var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-card',{attrs:{"title":_vm.$t('Create New Plate')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number ?false:null},model:{value:(_vm.data.number),callback:function ($$v) {_vm.$set(_vm.data, "number", $$v)},expression:"data.number"}}),(_vm.errors.number)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Number')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{attrs:{"value":true,"state":_vm.errors.multi ?false:null},model:{value:(_vm.data.multi),callback:function ($$v) {_vm.$set(_vm.data, "multi", $$v)},expression:"data.multi"}},[_vm._v(" "+_vm._s(_vm.$t('Multi'))+" ")])],1),(_vm.errors.multi)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.multi[0]))]):_vm._e()]),(_vm.data.multi!==true)?_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{attrs:{"value":true,"state":_vm.errors.available ?false:null},model:{value:(_vm.data.available),callback:function ($$v) {_vm.$set(_vm.data, "available", $$v)},expression:"data.available"}},[_vm._v(" "+_vm._s(_vm.$t('Available'))+" ")])],1),(_vm.errors.available)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.available[0]))]):_vm._e()]):_vm._e(),(_vm.data.multi===true)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.count ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberE'),"state":_vm.errors.count ?false:null},model:{value:(_vm.data.count),callback:function ($$v) {_vm.$set(_vm.data, "count", $$v)},expression:"data.count"}}),(_vm.errors.count)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.count[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('CountG')))])],1)]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"w-100 mb-1",attrs:{"variant":"gradient-success"},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")]),_c('b-button',{staticClass:"w-100",attrs:{"variant":"gradient-danger"},on:{"click":function($event){_vm.data={},_vm.errors=[]}}},[_vm._v(" "+_vm._s(_vm.$t('Reset Form'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }